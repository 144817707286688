import "./App.css";
import Home from "./Components/Home/Home";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Buynow from "./Components/Buynow/Buynow";
import Workshop from "./Components/Workshop/Workshop";
import Tutorial from "./Components/Tutorial/Tutorial";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Home></Home>
          </Route>
          <Route exact path="/home">
            <Home></Home>
          </Route>
          <Route path="/workshop">
            <Workshop></Workshop>
          </Route>
          <Route path="/buynow">
            <Buynow></Buynow>
          </Route>
          <Route path="/tutorial">
            <Tutorial></Tutorial>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
