import React from "react";
// import About from "../About/About";
// import Banner from "../Banner/Banner";
// import Buynow from "../Buynow/Buynow";
// import Footer from "../Footer/Footer";
import Reviews from "../Reviews/Reviews";

// import Topbar from "../Topbar/Topbar";
// import Tutorial from "../Tutorial/Tutorial";
// import Workshop from "../Workshop/Workshop";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

import { Col, Row, Card, Button } from "react-bootstrap";
import background from "../../assests/banner.webp";
import about from "../../assests/about.webp";
import p1 from "../../assests/p1.webp";
import p2 from "../../assests/p2.webp";
import p3 from "../../assests/p3.webp";
import p4 from "../../assests/p4.webp";

import daffodil from "../../assests/Daffodil International School (1).jpg";
import Engineering from "../../assests/Engineering University School & College (2).jpg";
import udayan from "../../assests/Udayan higher Secondary School (1).jpg";

const backgroundimg = {
  background: `url(${background})`,
  height: "90vh",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

const Home = () => {
  return (
    <div>
      {/* <Topbar></Topbar> */}
      <div>
        <Navbar collapseOnSelect expand="lg" bg="white" variant="white">
          <Container>
            <Navbar.Brand as={Link} to="/home" style={{ color: "black" }}>
              Onubikkhon.Com
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <div>
                <Nav className="me-auto">
                  <Nav.Link as={Link} to="/home" style={{ color: "black" }}>
                    Home
                  </Nav.Link>
                  <Nav.Link as={Link} to="/buynow" style={{ color: "black" }}>
                    Products
                  </Nav.Link>
                  <Nav.Link as={Link} to="/tutorial" style={{ color: "black" }}>
                    Tutorial
                  </Nav.Link>
                  <Nav.Link as={Link} to="/workshop" style={{ color: "black" }}>
                    Workshop
                  </Nav.Link>
                  <Nav.Link as={Link} to="/reviews" style={{ color: "black" }}>
                    Reviews
                  </Nav.Link>
                </Nav>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div style={backgroundimg}>
        <Row xs={1} md={1} lg={1}>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "35vh",

              color: "black",
            }}
          >
            <h3 style={{ textAlign: "center", fontWeight: "1000" }}>
              Onubikkhon.Com
              <br />
              <span style={{ fontWeight: "100", paddingTop: "20px" }}>
                {" "}
                The First Authorized Distributor of{" "}
                <span style={{ background: "orange", padding: "0px 10px" }}>
                  Foldscope
                </span>{" "}
                <br /> In Bangladesh
              </span>
            </h3>
          </Col>
        </Row>
      </div>
      {/* <Banner></Banner> */}
      {/* <About></About> */}
      <div style={{ padding: "100px 0px" }}>
        <Container>
          <Row xs={1} md={2} lg={2} className="g-4">
            <Col>
              <Card
                style={{
                  border: "none",
                  margin: "0 auto",
                  alignItems: "center",
                }}
              >
                <Card.Img
                  style={{ height: "200px", width: "50%", margin: "30px auto" }}
                  variant="top"
                  src={about}
                  className="img-fluid"
                />
              </Card>
            </Col>
            <Col>
              <h4 style={{ textAlign: "center" }}>The Foldscope Microscope</h4>
              <Card style={{ border: "none", width: "60%", margin: "0 auto" }}>
                <Card.Body>
                  Foldscope is the paper microscope that began as an idea to
                  make science more accessible. That idea has grown into a
                  company that now delivers low-cost tools around the world!
                  Foldscope has reached over 1.6 million people with our
                  foldable paper microscopes, and we have created an online
                  community of explorers unlike any other.
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <Buynow></Buynow> */}
      <Container>
        <h1 style={{ textAlign: "center", margin: "40px 0px" }}>Products</h1>
        <div style={{ margin: "0 10%" }}>
          <Row xs={1} md={2} lg={2} className="g-4">
            <Col>
              <Card>
                <Card.Img
                  style={{ height: "300px" }}
                  variant="top"
                  src={p1}
                  className="img-fluid"
                />
                <Card.Body>
                  <Card.Title>Foldscope Assembled Pouch Paper .</Card.Title>
                  <Card.Text>BDT 150</Card.Text>
                  <Button
                    style={{ background: "#1C96B9" }}
                    // onClick={() => setModalShow(true)}
                  >
                    Buy Now
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img
                  style={{ height: "300px" }}
                  variant="top"
                  src={p2}
                  className="img-fluid"
                />
                <Card.Body>
                  <Card.Title>Foldscope Assembled Individual</Card.Title>
                  <Card.Text>BDT 250</Card.Text>
                  <Button
                    style={{ background: "#1C96B9" }}
                    // onClick={() => setModalShow(true)}
                  >
                    Buy Now
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img
                  style={{ height: "300px" }}
                  variant="top"
                  src={p3}
                  className="img-fluid"
                />
                <Card.Body>
                  <Card.Title>Foldscope Assembled Classroom Kit</Card.Title>
                  <Card.Text>BDT 350</Card.Text>
                  <Button
                    style={{ background: "#1C96B9" }}
                    // onClick={() => setModalShow(true)}
                  >
                    Buy Now
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img
                  style={{ height: "300px" }}
                  variant="top"
                  src={p4}
                  className="img-fluid"
                />
                <Card.Body>
                  <Card.Title>Basic Classroom Kit</Card.Title>
                  <Card.Text>BDT 250</Card.Text>
                  <Button
                    style={{ background: "#1C96B9" }}
                    // onClick={() => setModalShow(true)}
                  >
                    Buy Now
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        {/* <Modal show={modalShow} onHide={() => setModalShow(false)}></Modal> */}
      </Container>
      <Container>
        <h3 style={{ textAlign: "center", margin: "40px 0px" }}>
          Our Workshops
        </h3>
        <div style={{ margin: "0 5%" }}>
          <Row xs={1} md={2} lg={2} className="g-4">
            <Col>
              <Card>
                <Card.Img variant="top" src={daffodil} className="img-fluid" />
                <Card.Body>
                  <Card.Title>Daffodil International School</Card.Title>
                  <Card.Text>
                    We went Daffodil International School for selling our
                    products there.We have beed able to get the attention of
                    students there
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img
                  variant="top"
                  src={Engineering}
                  className="img-fluid"
                />
                <Card.Body>
                  <Card.Title>
                    Engineering University School & College
                  </Card.Title>
                  <Card.Text>
                    We went Daffodil International School for selling our
                    products there.We have beed able to get the attention of
                    students there
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={udayan} className="img-fluid" />
                <Card.Body>
                  <Card.Title>Udayan Higher Secondary School</Card.Title>
                  <Card.Text>
                    We went Daffodil International School for selling our
                    products there.We have beed able to get the attention of
                    students there
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      {/* <Workshop></Workshop> */}
      {/* <Tutorial></Tutorial> */}
      <div style={{ padding: "120px 0px" }}>
        <h1 style={{ textAlign: "center", padding: "20px 0px" }}>Tutorial</h1>
        <Container>
          <Row xs={1} md={1} lg={1}>
            <Col>
              <Card style={{ border: "none", width: "70%", margin: "0 auto" }}>
                <Card.Body>
                  <iframe
                    width="100%"
                    height="400"
                    src="https://www.youtube.com/embed/HZyiVmXQND0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Reviews></Reviews>
      {/* <Footer></Footer> */}
      <div>
        <p
          className="responsive-font-example"
          style={{
            textAlign: "center",
            background: "black",
            color: "white",
            padding: "20px",
          }}
        >
          &copy;All Rights are reserved by Onubikkhon.Com || 2019
        </p>
      </div>
    </div>
  );
};

export default Home;
