import React from "react";

const Footer = () => {
  return (
    <div>
      <p
        className="responsive-font-example"
        style={{
          textAlign: "center",
          background: "black",
          color: "white",
          padding: "20px",
        }}
      >
        &copy;All Rights are reserved by Onubikkhon.Com || 2019
      </p>
    </div>
  );
};

export default Footer;
